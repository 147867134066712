import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import PageTemplate from '../templates/pageTemplate';
import PostLink from "../components/post-link"

const BlogIndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => edge.node.frontmatter.path.includes("/recipes/"))
    .map(edge => <PostLink key={edge.node.id} post={edge.node} hideDate={true} />)
  
  return (
    <PageTemplate
      title="Easy and Delicious Keto Recipes"
      description="These keto recipes and quick and easy to make, and delicious. You'll never feel like you're giving up a thing while on keto."
    >
      <h1>Easy and Delicious Keto Recipes</h1>
      <p>
        These keto recipes and quick and easy to make, and delicious. You'll never feel like you're giving up a thing while on keto.
        Keep your carb intake low, and get plenty of fat and protein. 
      </p>

      <PostsContainer>
        {Posts}
      </PostsContainer>
    </PageTemplate>
  ) 
}

export default BlogIndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            image {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const PostsContainer =  styled.div`
  margin-top: 50px;

`
