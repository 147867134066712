import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const PostLink = ({ post, hideDate }) => {
  const featuredImg = post.frontmatter.image.childImageSharp.fluid;

  return (
    <BlogLink href={post.frontmatter.path}>

      <BlogLinkImg fluid={featuredImg}/>

      <BlogLinkOverlay>
        <h2>{post.frontmatter.title}</h2>
        {!hideDate && <p>Posted on {post.frontmatter.date}</p>}
      </BlogLinkOverlay>

    </BlogLink>
  )
}

const BlogLinkImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`

const BlogLink = styled.a`
  position: relative;
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  width: 48%;
  margin: 5px 1%;

  @media(max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`

const BlogLinkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  height: 100%;
  
  background: rgba(0, 0, 0, 0.45);

  h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin: 0;
  }
`

export default PostLink